import { graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import SEORevamp from "../components/common/SEO_Revamp"
import TwoCards from "../components/common/TwoCards"
import Banner from "../components/industry-sections/Banner"
import GitStrip from "../components/industry-sections/GitStrip"
import HowTo from "../components/industry-sections/HowTo"
import SubServices from "../components/industry-sections/SubServices"
import WhyIz from "../components/industry-sections/WhyIz"
import AngularTechStacks from "../components/application-development/AngularTechStacks"
import AngularTechStacksMobile from "../components/application-development/AngularTechStacksMobile"
import MainLayout from "../layouts/MainLayout"

const IndustryTemplate = ({ data }) => {
  // const { seo, industryPage: industry } = data.strapiPage
  // const { banner, subServices, gitStrip, whyIz, howTo } = industry

  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 768)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const sec_1 = data?.strapiPage?.sections[0]
  const sec_2 = data?.strapiPage?.sections[1]
  const sec_3 = data?.strapiPage?.sections[2]
  const sec_4 = data?.strapiPage?.sections[3]
  const sec_5 = data?.strapiPage?.sections[4]
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout bgChanged={false} schemas={bodySchema}>
      <Banner {...sec_1} breadCrumb={data?.strapiPage?.buttons} />
      <SubServices {...sec_2} />
      <GitStrip {...sec_3} />
      <WhyIz {...sec_4} />
      <TwoCards bgColor="#f2f2f2" />
      <HowTo {...sec_5} />
      {!isMobile && !isTablet ? (
        <AngularTechStacks
          heading={"Main Development Technologies We Work With"}
        />
      ) : (
        <AngularTechStacksMobile
          heading={"Main Development Technologies We Work With"}
        />
      )}
      <ContactSales />
    </MainLayout>
  )
}

export const query = graphql`
  query getIndustryData {
    strapiPage(slug: { eq: "travel-tourism" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default IndustryTemplate

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  const sec_1 = data?.strapiPage?.sections[0]
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image={sec_1?.secImages && sec_1?.secImages[0]?.url}
    />
  )
}
