import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import dedicatedRemote from "../../images/icons/Dedicated-Remote-Team.svg"
import projectBased from "../../images/icons/Project-Based-Model.svg"
import * as styles from "./TwoCards.module.scss"

const TwoCards = ({ bgColor = "#fff" }) => (
  <section style={{ backgroundColor: bgColor }}>
    <div className="text-center mb-5">
      <h2>
        <span className="text-primary">Entrust Your Technology</span>{" "}
        Development <br /> Needs to InvoZone
      </h2>
    </div>
    <Container>
      <Row>
        <Col md={12} lg={6} className="mt-3 mt-lg-0">
          <div className={`${styles.card} ${styles.cardLeft}`}>
            <div>
              <img
                src={dedicatedRemote}
                alt="remote team service"
                height={116}
                width={114}
                placeholder="blurred"
                decoding="async"
                loading="lazy"
              />
            </div>
            <div className="pl-0 pl-md-3">
              <h3>Dedicated Remote Team</h3>
              <p className={styles.minheiht}>
                If you’re looking for an offshore development team, this remote
                model perfectly matches your requirements. Hire a dedicated
                remote team and get 100% control over your resources.
              </p>
              <div>
                <Link to="/dedicated-development-team/">
                  <button className="btn_white_border">Build your team</button>
                </Link>
              </div>
            </div>
          </div>
        </Col>
        <Col md={12} lg={6} className="mt-3 mt-lg-0">
          <div className={`${styles.card} ${styles.cardRight}`}>
            <div>
              <img
                src={projectBased}
                alt="project base service"
                placeholder="blurred"
                decoding="async"
                loading="lazy"
                height={116}
                width={114}
              />
            </div>
            <div className="pl-0 pl-md-3">
              <h3>Project-Based Model</h3>
              <p className={styles.minheiht}>
                Want to focus on business? Hand in your tech development to us.
                We define expected deliverables and timelines with a fixed
                budget.
              </p>
              <div>
                <Link to="/contact-us/">
                  <button className="btn_white_border">
                    Discuss your project
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
)

export default TwoCards
