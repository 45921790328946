import { Link } from "gatsby"
import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./GitStrip.module.scss"

const GitStrip = props => {
  const { title } = props
  return (
    <section className={styles.section}>
      <Container>
        <Row className="align-items-center">
          <Col xl={8} className="py-2">
            <h2 className="text-light mb-0">{title}</h2>
          </Col>
          <Col xl={4} className="py-2">
            <Link to="/contact-us/">
              <Button variant="mt-3 mt-xl-0" className="btn_white_border">
                Get in touch
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default GitStrip
