import React from "react"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import * as styles from "./HowTo.module.scss"

const HowTo = props => {
  const { title, description } = props

  return (
    <section>
      <Container>
        <div className="text-center mb-5">
          <h2 className="h1" dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: description?.description }}
        />
        <div className="mt-5 text-center">
          <a href="/blog/" target="_blank">
            <Button className="btn_black_border">Read More Articles</Button>
          </a>
        </div>
      </Container>
    </section>
  )
}

export default HowTo
