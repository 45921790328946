import React from "react"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./SubServices.module.scss"

const SubServices = props => {
  const { title, cards } = props
  return (
    <section className={styles.section}>
      <Container>
        <div className="text-center">
          <h2
            className="h1 main-heading-36"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
        <Row>
          {cards?.map(({ image1, title, subTitle }, i) => (
            <Col md={6} lg={4} key={i} style={{ padding: 15 }}>
              <Card className={styles.industrySubSerCard}>
                <Card.Body>
                  <div className="mb-3">
                    <img
                      src={image1[0]?.localFile?.publicURL}
                      alt={title}
                      width={48}
                      decoding="async"
                      loading="lazy"
                    />
                  </div>
                  <Card.Title>
                    <h3 className="h4">{title}</h3>
                  </Card.Title>
                  <p
                    dangerouslySetInnerHTML={{ __html: subTitle }}
                    className={styles.descriptionTxt}
                  />
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default SubServices
