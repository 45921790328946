import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

const WhyIz = props => {
  const { title, cards } = props
  return (
    <section>
      <Row className={`align-items-center mr-0 pl-md-5`}>
        <Col xs={12} md={6} className="pl-md-5">
          <Col xs={12}>
            <h2
              className="h1 main-heading-36 mb-4"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </Col>
          <ol className="mt-0">
            {cards.map(({ title }, i) => (
              <li className="pb-2" key={i}>
                {title}
              </li>
            ))}
          </ol>
          <div className="mt-5 pl-3">
            <Link to="/contact-us/">
              <Button className="btn_black_border">Let us Call You</Button>
            </Link>
          </div>
        </Col>
        <Col xs={12} md={6} className="text-right mt-5 mt-md-0 px-md-0">
          <StaticImage
            src="../../images/why-iz-illustration.png"
            alt="why InvoZone"
            placeholder="blurred"
            decoding="async"
            loading="lazy"
            className="ml-2 ml-md-5"
          />
        </Col>
      </Row>
    </section>
  )
}

export default WhyIz
