import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const Banner = props => {
  const { title, subTitle, buttons, secImages } = props
  return (
    <section className={styles.section}>
      <Container>
        <nav className="mb-4">
          <Link to={`/`} className="text-dark">
            Home
          </Link>
          {" » "}
          <Link to={`/industries/`} className="text-dark">
            Industries
          </Link>
          {" » "}
          <span className="text-dark">{props?.breadCrumb[0]?.title}</span>
        </nav>
        <Row className="align-items-center">
          <Col md={6}>
            <h1
              className="main-banner-heading-55"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div dangerouslySetInnerHTML={{ __html: subTitle }} />
            <div className="mt-5">
              <Link to={buttons[0]?.url}>
                <Button className="btn_black_border">
                  {buttons[0]?.title}
                </Button>
              </Link>
            </div>
          </Col>
          <Col md={6} className="text-center mt-5 mt-md-0">
            <GatsbyImage
              placeholder="blurred"
              decoding="async"
              loading="lazy"
              image={getImage(secImages[0].localFile)}
              alt="banner image"
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Banner
